*:not(html) {
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

html {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
}

p {
  &:first-of-type {
    margin-block-start: 0;
  }

  &:last-of-type {
    margin-block-end: 0;
  }
}

img {
  background: {
    repeat: no-repeat;
    size: cover;
  }
  font: {
    size: 0.625rem;
    style: italic;
  }
  vertical-align: middle;
  shape-margin: 0.75rem;
}

img:not(.fixed-size-img) {
  height: auto;
  max-width: 100%;
}
