@use "../settings/typography" as *;

:root {
  @each $family, $value in $font-families {
    --font-family-#{$family}: #{unquote($value)};
  }

  @each $weight, $value in $font-weights {
    --font-weight-#{$weight}: #{$value};
  }

  @each $size, $value in $font-sizes {
    --font-size-#{$size}: #{$value};
  }

  @each $size, $value in $line-heights {
    --line-height-#{$size}: #{$value};
  }
}
