.u-link {
  --_u-link-color-normal: var(--color-link);
  color: var(--_u-link-color-normal);
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}
