.u-form-element-wrapper > mat-label {
  --_mat-label-color: #fff;
  --_mat-label-font-weight: var(--font-weight-light);
  --_mat-label-margin-bottom: var(--space-xxs);

  display: block;
  color: var(--_mat-label-color);
  font-weight: var(--_mat-label-font-weight);
  margin-bottom: var(--_mat-label-margin-bottom);
}
