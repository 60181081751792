.u-form-element-wrapper {
  --_u-form-element-wrapper-margin-y: 1rem;

  // google button
  asl-google-signin-button {
    display: flex;
    justify-content: center;
  }

  &:not(:first-of-type) {
    margin-block-start: var(--_u-form-element-wrapper-margin-y);
  }

  &:not(:last-of-type) {
    margin-block-end: var(--_u-form-element-wrapper-margin-y);
  }
}

.u-form-field-full {
  width: 100%;
}

.u-form-element-required-star {
  color: var(--color-danger-50);
}
