.mat-mdc-form-field {
  --mdc-outlined-text-field-container-shape: 28px;
  --mdc-shape-small: var(--mdc-outlined-text-field-container-shape);
  --mat-select-enabled-trigger-text-color: var(--color-neutral-10);
  --mat-form-field-container-text-font: var(--font-family-base);
  --mat-select-trigger-text-font: var(--font-family-base);
  --_mat-mdc-form-field-autofill-color: var(--color-neutral-10);
  --_mat-mdc-form-field-placeholder-color: var(--color-neutral-10);
  --_mat-mdc-form-field-icon-color: var(--color-neutral-10);
  --_mat-mdc-form-field-color: var(--color-neutral-10);
  --_mat-mdc-form-field-focus-inner-shadow: 0 0 5px 3px rgb(0 0 0 / 0.2) inset;

  border-radius: var(--mdc-outlined-text-field-container-shape);

  &:focus-within {
    -webkit-text-fill-color: var(--_mat-mdc-form-field-autofill-color);
    box-shadow: 0 0 0 40rem #fff inset;
  }

  .mat-mdc-input-element,
  .mat-mdc-input-element:-webkit-autofill,
  .mat-mdc-input-element::placeholder,
  .mat-mdc-select-value,
  .mat-mdc-select-placeholder {
    color: var(--_mat-mdc-form-field-color);
    font-family: inherit !important;
    font-weight: inherit !important;
  }

  .mdc-text-field {
    background-color: #fff;
    border-radius: var(--mdc-outlined-text-field-container-shape) !important;
  }

  .mdc-text-field__input:-webkit-autofill,
  .mdc-text-field__input:autofill,
  .mdc-text-field__input:-internal-autofill-selected {
    -webkit-text-fill-color: var(--_mat-mdc-form-field-autofill-color);
    box-shadow: 0 0 40rem #fff inset !important;
  }

  .mdc-text-field:focus-within {
    box-shadow: var(--_mat-mdc-form-field-focus-inner-shadow) !important;
  }

  // .mdc-text-field:has(input:focus) {
  //   border-radius: var(--mdc-outlined-text-field-container-shape);
  //   box-shadow: var(--_mat-mdc-form-field-focus-inner-shadow) !important;
  // }
  //
  // .mdc-text-field:has(mat-select:focus) {
  //   border-radius: var(--mdc-outlined-text-field-container-shape);
  //   box-shadow: var(--_mat-mdc-form-field-focus-inner-shadow) !important;
  // }

  input::placeholder {
    color: var(--_mat-mdc-form-field-placeholder-color) !important;
    font-family: inherit !important;
    font-weight: inherit !important;
  }

  .mat-mdc-select-placeholder {
    color: var(--_mat-mdc-form-field-placeholder-color) !important;
  }

  .mat-mdc-select-arrow {
    color: var(--_mat-mdc-form-field-icon-color) !important;
  }

  // .mat-mdc-select-value-text {
  //   color: var(--_mat-mdc-form-field-color) !important;
  // }

  .mat-mdc-icon-button {
    color: var(--_mat-mdc-form-field-icon-color) !important;
  }
}


.mdc-text-field--outlined:not(
  :where(
    .mdc-text-field--disabled,
    .mdc-text-field--invalid
  )
) :where(
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing
) {
  border-color: var(--color-transparent) !important;
  outline: {
    color: var(--color-transparent);
    style: solid;
    width: 1px;
  }
}

.c-checkout__header {

  // mat-label {
  //   color: var(--_c-checkout-date-label-color);
  //   font-size: var(--_c-checkout-date-font-size);
  //   font-weight: var(--_c-checkout-date-font-weight);
  // }
}

.c-checkout__date {
  .mdc-label {
    color: var(--_c-checkout-date-label-color);
    font-size: var(--_c-checkout-date-font-size) !important;
    font-weight: var(--_c-checkout-date-font-weight) !important;
  }

  .mat-form-field-disabled {

    input,
    .mat-mdc-form-field-icon-suffix {
      opacity: .5;
    }
  }

  .mat-mdc-slide-toggle.mat-accent,
  .mdc-switch--selected.mdc-switch--checked{
    --mdc-switch-selected-focus-state-layer-color: var(--color-neutral-90) !important;
    --mdc-switch-selected-hover-state-layer-color: var(--color-neutral-90) !important;
    --mdc-switch-selected-pressed-state-layer-color: var(--color-neutral-90) !important;
    --mdc-switch-selected-handle-color: var(--color-success-60) !important;
    --mdc-switch-selected-focus-handle-color: var(--color-success-60) !important;
    --mdc-switch-selected-hover-handle-color: var(--color-success-60) !important;
    --mdc-switch-selected-pressed-handle-color: var(--color-success-60) !important;
    --mdc-switch-selected-focus-track-color: var(--color-neutral-90) !important;
    --mdc-switch-selected-hover-track-color: var(--color-neutral-90) !important;
    --mdc-switch-selected-pressed-track-color: var(--color-neutral-90) !important;
    --mdc-switch-selected-track-color: var(--color-neutral-90) !important;
  }

  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    display: none;
  }

  .mat-mdc-form-field-infix {
    min-height: 38px !important;
  }

  .mdc-text-field__input {
    color: var(--_c-checkout-date-input-color) !important;
    font-weight: var(--_c-checkout-date-font-weight) !important;
  }
}

.c-product-item__quantity-value-mat-mdc-form-field {

  .mat-mdc-text-field-wrapper {
    padding: 0 11px !important;
  }

  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    display: none;
  }

  .mat-mdc-form-field-infix {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    min-height: 40px !important;
    height: 40px !important;
    width: 20px !important;
    min-width: unset !important;
  }
}

app-language-switcher {
  .mat-mdc-menu-trigger {
    display: block;
    height: 24px !important;
  }
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  --_mat-mdc-form-field-checkbox-color: var(--color-secondary);

  background-color: var(--_mat-mdc-form-field-checkbox-color) !important;
  border-color: var(--_mat-mdc-form-field-checkbox-color) !important;
}


.c-checkout__date-start {
  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-icon-suffix {
        opacity: .2 !important;
      }
    }
  }
}

.c-register {

  // mat-label {
  //   display: block;
  //   color: var(--_c-register-label-color);
  //   font-weight: var(--_c-register-label-font-weight);
  //   margin-bottom: var(--_c-register-label-bottom-margin);
  // }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-checkbox {
    label {
      color: var(--_c-register-label-color);
    }
  }

  .u-form-checkbox-container {
    align-items: center;
    column-gap: 5px;
    display: grid;
    grid-template-columns: min-content auto;

    .mat-mdc-checkbox {
      label {
        color: var(--_c-register-checkbox-label-color);
      }
    }
  }
}

.c-password-reset {

  // mat-label {
  //   display: block;
  //   color: var(--_c-password-label-color);
  //   font-weight: var(--_c-password-label-font-weight);
  //   margin-bottom: var(--_c-password-label-bottom-margin);
  // }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.c-password-set-new {

  // mat-label {
  //   display: block;
  //   color: var(--_c-password-set-new-label-color);
  //   font-weight: var(--_c-password-set-new-label-font-weight);
  //   margin-bottom: var(--_c-password-set-new-label-bottom-margin);
  // }

  // .mdc-text-field__input {
  //   color: var(--_c-password-set-new-input-color) !important;
  // }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.c-account {

  // mat-label {
  //   display: block;
  //   color: var(--_c-account-label-color);
  //   font-weight: var(--_c-account-label-font-weight);
  //   margin-bottom: var(--_c-account-label-bottom-margin);
  // }

  // .mdc-text-field__input {
  //   color: var(--_c-account-input-color) !important;
  // }

  // .mat-mdc-select-value {
  //   color: var(--_c-account-input-color) !important;
  // }

  .mat-mdc-form-field-subscript-wrapper:not(:empty) {
    display: none;
  }

  // .mat-mdc-select-arrow {
  //
  //   svg {
  //     color: var(--_c-account-select-icon-color);
  //   }
  // }
}

.c-password-change {

  // mat-label {
  //   display: block;
  //   color: var(--_c-password-change-label-color);
  //   font-weight: var(--_c-password-change-label-font-weight);
  //   margin-bottom: var(--_c-password-change-label-bottom-margin);
  // }

  // .mdc-text-field__input {
  //   color: var(--_c-password-change-input-color) !important;
  // }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

// .c-cart {
//   .mdc-form-field {
//     color: var(--_c-cart-conditions-text-color);
//   }
// }

.c-panel__header-actions {

  --_c-form-select-background-color: var(--color-neutral-100);
  --_c-form-select-border-color: var(--color-secondary);

  .mat-mdc-form-field
  {
    .mat-mdc-form-field-infix {
      min-height: unset !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 20px !important;
    padding-bottom: 5px !important;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: var(--_c-form-select-background-color) !important;
    border: 1px solid var(--_c-form-select-border-color);
    border-radius: 5px !important;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }

  .mdc-line-ripple {
    display: none !important;
  }

}

/*  LOGIN OVERRIDE  */


.c-login {

  // mat-label {
  //   display: block;
  //   color: var(--_c-login-label-color);
  //   font-weight: var(--_c-login-label-font-weight);
  //   margin-bottom: var(--_c-login-label-bottom-margin);
  // }

  .mat-mdc-form-field {
    .mat-mdc-input-element {
      color: var(--_c-login-input-color);
      background-color: var(--_c-login-input-background-transparent);
    }

    mat-icon {
      color: var(--_c-login-input-icon-color);
    }
  }
}



.cdk-overlay-container {
  --vehicle-select-option-padding: var(--space-xs);
  --vehicle-select-option-line-height: 1.2;
  --vehicle-select-option-border-color: var(--color-neutral-90);
  // --option-select-color: var(--color-primary);

  .mat-mdc-select-panel {
    padding: 0 !important;
  }

  .mat-mdc-option {
    padding: var(--vehicle-select-option-padding) !important;
    font-size: var(--font-size-xs) !important;
    line-height: var(--vehicle-select-option-line-height) !important;
    min-height: 4lh !important;

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--vehicle-select-option-border-color);
    }

    // .c-select-product-item {
    //   column-gap: 15px !important;
    // }

    // .c-select-product-item__image {
    //   height: unset !important;
    //   width: unset !important;
    //
    //   img {
    //     width: 40px !important;
    //   }
    // }
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: var(--option-select-color);
  }

  .mdc-list-item__primary-text:has(.c-select-product-item) {
    flex-grow: 1;
  }
}

.cdk-overlay-container {
  --_c-calendar-active-background-color: var(--color-primary);
  --_c-calendar-today-background-color: var(--color-primary-90);
  --_c-calendar-hover-background-color: var(--color-background-primary-weak);
  --_c-calendar-active-text-color: var(--color-primary-100);


  .mat-calendar-body-selected {
    background-color: var(--_c-calendar-active-background-color) !important;
    color: var(--_c-calendar-active-text-color) !important;
  }

  .mat-calendar-body-today {
    background-color: var(--_c-calendar-today-background-color) !important;
    color: var(--color-neutral-30) !important;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--_c-calendar-hover-background-color) !important;
  }
}


@media (max-width: 380px) {
  .c-checkout__date {

    .mat-mdc-icon-button.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 40px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-top: 6px !important;
      padding-bottom: 0 !important;
    }

    .mat-mdc-text-field-wrapper {
      padding-left: 10px !important;
    }
  }
}

.mat-mdc-slide-toggle {
  --_c-slide-toggle-label-space: var(--space-s);

  .mdc-label {
    margin-right: var(--_c-slide-toggle-label-space) !important;
  }
}

.mdc-text-field--invalid {
  --_c-form-select-border-color: var(--color-danger-70) !important;
}

::placeholder {
  opacity: .75;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.mat-mdc-form-field-error {
  display: inline-block !important;
  margin-left: var(--space-m);
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  background-color: var(--color-danger-50);
  color: var(--color-info-100) !important;
  font-size: var(--font-size-xs);
}

.mat-mdc-form-field + .mat-mdc-form-field-error {
  margin-top: 1px !important;
}



