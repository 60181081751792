@use "../settings/layout" as layout;

.u-width-full {
  width: 100%;
}

.u-inline-block {
  display: inline-block;
}

$sides: inline, inline-start, inline-end, block, block-start, block-end;
@each $name, $value in layout.$spaces {
  // Margin.
  .u-margin-#{$name} {
    margin: var(--space-#{$name});
  }
  @each $side in $sides {
    .u-margin-#{$side}-#{$name} {
      margin-#{$side}: var(--space-#{$name});
    }
  }

  // Padding.
  .u-padding {
    padding: var(--space-#{$name});
  }
  @each $side in $sides {
    .u-padding-#{$side}-#{$name} {
      padding-#{$side}: var(--space-#{$name});
    }
  }
}
