.c-page-title {
  --_c-page-title-color: var(--color-neutral-100);
  --_c-page-title-font-size: var(--font-size-l);
  --_c-page-title-font-weight: var(--font-weight-bold);
  --_c-page-title-margin-y: var(--space-m);

  color: var(--_c-page-title-color);

  font: {
    size: var(--_c-page-title-font-size);
    weight: var(--_c-page-title-font-weight);
  }

  margin-block-start: 0; // Reset browser default.
  margin-block-end: var(--_c-page-title-margin-y);
  text-transform: uppercase;
}
