.u-color-inherit {
  color: inherit;
}

.u-color-primary {
  color: var(--color-primary);
}

.u-color-secondary {
  color: var(--color-primary);
}

.u-color-system-success {
  color: var(--color-system-success);
}

.u-color-system-danger {
  color: var(--color-system-danger);
}
