.mat-snackbar-success {
  .mdc-snackbar__surface {
    background: #00ad43 !important;
  }
}

.mat-snackbar-error {
  .mdc-snackbar__surface {
    background: #ff5251 !important;
  }
}
