@use "sass:meta";
@use "./settings/color";

//@import "@angular/material/prebuilt-themes/indigo-pink.css";

// ITCSS folder structure (+ extended structure)
//   https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
//
// Libs
// Vendor
// Properties
// Settings (there is no 'settings' @layer, we use 'properties' instead)
// Tools
// Themes
// Generic
// Elements
// Objects
// Components
// Utilities
// Overrides

// Composite layers - To keep specificity order.
@layer libs, vendor, properties, tools, themes, generic, elements, objects, components, utilities, overrides;

@layer vendor {
  @layer material {
    @include meta.load-css('./vendor/material/indigo-pink');
  }
}

@layer properties {
  @include meta.load-css('./properties/appearance');
  @include meta.load-css('./properties/color');
  @include meta.load-css('./properties/layout');
  @include meta.load-css('./properties/typography');
}

@layer generic {
  @include meta.load-css('./generic/layout');
  @include meta.load-css('./generic/color');
}

@layer elements {
  @include meta.load-css('./elements/layout');
  @include meta.load-css('./elements/typography');
  @include meta.load-css('./elements/color');
}

@layer components.scss {
  @include meta.load-css('./components/page-title');
}

@layer utilities {
  @include meta.load-css('./utilities/color');
  @include meta.load-css('./utilities/form');
  @include meta.load-css('./utilities/icon');
  @include meta.load-css('./utilities/layout');
  @include meta.load-css('./utilities/link');
  @include meta.load-css('./utilities/text');
  @include meta.load-css('./utilities/visual');
}

@layer overrides {
   @include meta.load-css('./overrides/mat-checkbox');
   @include meta.load-css('./overrides/mat-form-field');
   @include meta.load-css('./overrides/mat-label');
   @include meta.load-css('./overrides/mat-menu-item');
   @include meta.load-css('./overrides/mat-snack-bar');
   @include meta.load-css('./overrides/mat-option');
   @include meta.load-css('./overrides/mat-progress-spinner');
}
