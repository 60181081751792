html {
  color: var(--color-text-normal);
}

body {
  background: {
    attachment: fixed;
    color: var(--color-primary);
    image: url("../../images/bg.webp");
    position: center;
    repeat: no-repeat;
    size: cover;
  }
}

svg {
  fill: currentColor;
}
