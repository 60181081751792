/**
 * Colors.
 */
$colors: (
  'background-weak': var(--color-neutral-95),
  'background-normal': var(--color-neutral-100),
  'background-strong': var(--color-neutral-100),
  'background-primary-weak': var(--color-primary-95),
  'border-normal': var(--color-neutral-80),
  'text-normal': var(--color-neutral-100),
  'text-inverted': var(--color-neutral-20),
  'text-weak': var(--color-neutral-60),
  'link': var(--color-info-90),
  'transparent': oklch(0 0 0 / 0),
  'system-success': var(--color-success-50),
  'system-warning': var(--color-warning-50),
  'system-danger': var(--color-danger-50),
  'system-info': var(--color-info-50),
);
