@use "sass:map";
@use "sass:color";
@use "../settings/color" as *;

:root {
  // NEUTRAL
  --color-neutral: oklch(50% 0 0);
  --color-neutral-10: oklch(10% 0 0);
  --color-neutral-20: oklch(20% 0 0);
  --color-neutral-30: oklch(30% 0 0);
  --color-neutral-40: oklch(40% 0 0);
  --color-neutral-50: oklch(50% 0 0);
  --color-neutral-60: oklch(60% 0 0);
  --color-neutral-70: oklch(70% 0 0);
  --color-neutral-80: oklch(80% 0 0);
  --color-neutral-90: oklch(90% 0 0);
  --color-neutral-95: oklch(95% 0 0);
  --color-neutral-99: oklch(99% 0 0);
  --color-neutral-100: oklch(100% 0 0);

  // PRIMARY
  --color-primary: oklch(0.50 0.135 247);
  --color-primary-10: oklch(0.10 0.135 247);
  --color-primary-20: oklch(0.20 0.135 247);
  --color-primary-30: oklch(0.30 0.135 247);
  --color-primary-40: oklch(0.40 0.135 247);
  --color-primary-50: oklch(0.50 0.135 247);
  --color-primary-60: oklch(0.60 0.135 247);
  --color-primary-70: oklch(0.70 0.135 247);
  --color-primary-80: oklch(0.80 0.135 247);
  --color-primary-90: oklch(0.90 0.09 247);
  --color-primary-95: oklch(0.95 0.04 247);
  --color-primary-99: oklch(0.99 0.04 247);
  --color-primary-100: oklch(1 0 247);

  // SECONDARY
  --color-secondary:    oklch(0.80 0.18 63);
  --color-secondary-10: oklch(0.20 0.10 63);
  --color-secondary-20: oklch(0.30 0.11 63);
  --color-secondary-30: oklch(0.40 0.14 63);
  --color-secondary-40: oklch(0.60 0.16 63);
  --color-secondary-50: oklch(0.80 0.18 63);
  --color-secondary-60: oklch(0.85 0.18 63);
  --color-secondary-70: oklch(0.90 0.18 63);
  --color-secondary-80: oklch(0.95 0.18 63);
  --color-secondary-90: oklch(0.95 0.15 63);
  --color-secondary-95: oklch(0.97 0.14 63);
  --color-secondary-99: oklch(0.99 0.12 63);
  --color-secondary-100: oklch(1 0 63);

  // SUCCESS
  --color-success:    oklch(0.50 0.2 135);
  --color-success-10: oklch(0.10 0.2 135);
  --color-success-20: oklch(0.20 0.2 135);
  --color-success-30: oklch(0.30 0.2 135);
  --color-success-40: oklch(0.40 0.2 135);
  --color-success-50: oklch(0.50 0.2 135);
  --color-success-60: oklch(0.60 0.2 135);
  --color-success-70: oklch(0.70 0.2 135);
  --color-success-80: oklch(0.80 0.2 135);
  --color-success-90: oklch(0.87 0.2 135);
  --color-success-95: oklch(0.92 0.2 135);
  --color-success-99: oklch(0.99 0.15 135);
  --color-success-100: oklch(1 0 135);

  // WARNING
  --color-warning:    oklch(0.8 0.34 97);
  --color-warning-10: oklch(0.2 0.28 97);
  --color-warning-20: oklch(0.3 0.25 97);
  --color-warning-30: oklch(0.5 0.34 97);
  --color-warning-40: oklch(0.7 0.34 97);
  --color-warning-50: oklch(0.8 0.34 97);
  --color-warning-60: oklch(0.85 0.34 97);
  --color-warning-70: oklch(0.87 0.34 97);
  --color-warning-80: oklch(0.90 0.17 97);
  --color-warning-90: oklch(0.92 0.15 97);
  --color-warning-95: oklch(0.95 0.14 97);
  --color-warning-99: oklch(0.99 0.12 97);
  --color-warning-100: oklch(1 0 97);

  // DANGER
  --color-danger:    oklch(0.5 0.3 30);
  --color-danger-10: oklch(0.1 0.3 30);
  --color-danger-20: oklch(0.2 0.3 30);
  --color-danger-30: oklch(0.3 0.3 30);
  --color-danger-40: oklch(0.4 0.3 30);
  --color-danger-50: oklch(0.5 0.3 30);
  --color-danger-60: oklch(0.6 0.3 30);
  --color-danger-70: oklch(0.7 0.3 30);
  --color-danger-80: oklch(0.8 0.3 30);
  --color-danger-90: oklch(0.9 0.3 30);
  --color-danger-95: oklch(0.95 0.3 30);
  --color-danger-99: oklch(0.99 0.3 30);
  --color-danger-100: oklch(1 0 30);

  // INFO
  --color-info: oklch(0.5 0.23 262);
  --color-info-10: oklch(0.1 0.23 262);
  --color-info-20: oklch(0.2 0.23 262);
  --color-info-30: oklch(0.3 0.23 262);
  --color-info-40: oklch(0.4 0.23 262);
  --color-info-50: oklch(0.5 0.23 262);
  --color-info-60: oklch(0.6 0.23 262);
  --color-info-70: oklch(0.7 0.23 262);
  --color-info-80: oklch(0.8 0.23 262);
  --color-info-90: oklch(0.9 0.23 262);
  --color-info-95: oklch(0.95 0.23 262);
  --color-info-99: oklch(0.99 0.23 262);
  --color-info-100: oklch(1 0 262);

  @each $color, $value in $colors {
    --color-#{$color}: #{$value};
  }
}
